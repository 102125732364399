"use client";
import { usePathname, useRouter } from "next/navigation";
import styles from "@/app/styles/components/header.module.scss";
import Link from "next/link";
import Image from "next/image";
import { useContext, useEffect, useState } from "react";
import UpIcon from "@/app/components/icons/UpIcon";
import DropDownIcon from "@/app/components/icons/DropDownIcon";
import CartPopUpModal from "@/app/components/common/CartPopUpModal";
import { useCartHook } from "@/app/hooks/useCartHook";
import CartModalContext from "@/app/context/CartModalContext";
import { getCart } from "@/app/services/CartService";

const HeaderNav = ({ data }) => {
  const options = data.dataOptions?.acf;
  const router = useRouter();
  const { isCartModalOpen, setIsCartModalOpen, reload, setReload } =
    useContext(CartModalContext);

  const [isOpen, setIsOpen] = useState(isCartModalOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartProductCount, setCartProductCount] = useState(0);
  const { cart } = useCartHook({ cookieData: data.cookieCart });

  const cartProductIds = Object.keys(cart);
  const productsInCart = data?.products.filter((product) =>
    cartProductIds.includes(product.id.toString()),
  );
  const pinkHeader = data?.dataOptions?.acf?.pink_header;
  const pinkHeaderLogo = data?.dataOptions?.acf.pink_header_logo;
  const path = usePathname();

  useEffect(() => {
    if (isCartModalOpen) {
      setIsModalOpen(isCartModalOpen);
    }
  }, [isCartModalOpen]);

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleSeeCollagen = () => {
    router.push("/crux-collagen");
  };

  const handleSeeAshwagandha = () => {
    router.push("/crux-ashwagandha");
  };

  const calculateDiscount = (newProduct, cart) => {
    const sum = Object.values(cart).reduce((acc, currentValue) => {
      return acc + currentValue[0].quantity;
    }, 0);
    const totalPrice = calculateSubtotal(newProduct, cart);
    if (sum === 2) {
      return (totalPrice * 5) / 100;
    } else if (2 < sum && sum <= 4) {
      return (totalPrice * 10) / 100;
    } else if (sum >= 5) {
      return (totalPrice * 15) / 100;
    } else return 0;
  };

  const real = () => {
    return (
      calculateTotal() -
      calculateDiscount(
        data.products.filter((product) => cart[product.id]),
        cart,
      )
    );
  };

  function calculateSubtotal(newProducts, cart) {
    return newProducts.reduce((acc, product) => {
      const quantity = cart[product.id][0]?.quantity || 0;
      const price = product?.price || 0;
      return acc + price * quantity;
    }, 0);
  }

  const calculateTotal = () => {
    return productsInCart.reduce((total, product) => {
      return (
        total +
        product.price *
          (cart[product.id][0]?.quantity ? cart[product.id][0]?.quantity : 1)
      );
    }, 0);
  };

  const openModal = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "view_cart",
      value: real() || parseFloat(0),
      ecommerce: {
        currency: "RSD",
        items: productsInCart.map((item) => ({
          item_id: item.id || -1,
          item_name: item.name || " ",
          category: item?.categories[0]?.name || "Nedefinisano",
          variant: item.variant || "Nedefinisano",
          price: item.price || 0,
          quantity: cart[item.id] ? cart[item.id][0]?.quantity : 1,
        })),
      },
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getCart().then((response) => {
      if (response) {
        const ids = Object.keys(response);
        if (ids.length === 0) {
          setCartProductCount(0);
          return;
        }
        if (ids.length) {
          setCartProductCount(ids.length);
        }
      }
    });
  }, [cart, reload]);
  const [isScrolled, setIsScrolled] = useState(true);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       // Change 500 to the height of your first section
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };
  //
  //   window.addEventListener("scroll", handleScroll);
  //
  //   // Clean up the event listener on unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const headerBackgroundColor = () => {
    if (pinkHeader && path === "/borba-protiv-raka-dojke") {
      return "!bg-[#ECDADC]";
    }
    if (path === "/") {
      return `${isScrolled ? "" : "!bg-[transparent]"}`;
    }
    return "";
  };
  const headerTextColor = () => {
    if (path === "/") {
      return `${isScrolled ? "text-cruxBrown" : "text-white leading-[28px]"}`;
    }
    return "";
  };

  return (
    <div
      className={`${styles.headerMainContainer} ${isOpen ? styles.bgWhite : headerBackgroundColor()} `}
    >
      <div className={"flex flex-col items-center"}>
        <div
          className={`${styles.headerContainer} ${isOpen ? styles.bgWhite : headerBackgroundColor()} `}
        >
          <div
            className={`flex flex-row items-center mx-auto lg:gap-[40px] xl:gap-[40px] xxl:gap-[60px] 2xl:gap-[80px] 3xl:gap-[80px] text-cruxBrown text-[16px] ${headerTextColor()} ${isOpen ? "!text-cruxBrown" : ""}`}
          >
            <Link href="/" className="hover:text-black cursor-pointer">
              Početna
            </Link>
            <div className="flex flex-row items-center gap-2 cursor-pointer">
              <div
                onClick={() => setIsOpen(!isOpen)}
                onMouseEnter={() => setIsOpen(true)}
                className="hover:text-black cursor-pointer"
              >
                Proizvodi
              </div>
              {isOpen ? (
                <div onClick={() => setIsOpen(false)}>
                  <UpIcon />
                </div>
              ) : (
                <div onClick={() => setIsOpen(true)}>
                  <DropDownIcon />
                </div>
              )}
            </div>
            <Link
              href="/blog?page=1"
              className="hover:text-black cursor-pointer"
            >
              Blog
            </Link>
            <div className={styles.headerLogo}>
              <Image
                className={styles.logoImage}
                src={
                  pinkHeader && path === "/borba-protiv-raka-dojke"
                    ? pinkHeaderLogo
                    : "/header-logo.svg"
                }
                alt="Crux logo"
                width={40}
                height={41}
              />
              <a href="/" className={styles.logoLink}></a>
            </div>
            <Link
              href="/prodavnica"
              className="hover:text-black cursor-pointer"
            >
              Prodavnica
            </Link>
            <Link href="/utisci" className="hover:text-black cursor-pointer">
              Iskustva
            </Link>
            <Link href="/kontakt" className="hover:text-black cursor-pointer">
              Kontakt
            </Link>
          </div>
          <div className="flex flex-row fixed right-0 lg:mr-[5%] xl:mr-[10%] gap-[10px]">
            <a
              href="https://www.instagram.com/crux_pure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  path === "/" && !isScrolled
                    ? options.social_media_repeater[0].instagram_icon
                    : options.header_icon_repeater[0].header_icon
                }
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/cruxpure.rs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  path === "/" && !isScrolled
                    ? options.social_media_repeater[0].facebook_icon
                    : options.header_icon_repeater[1].header_icon
                }
                alt="Facebook"
              />
            </a>
            <div>
              <img
                src={
                  path === "/" && !isScrolled
                    ? options.header_icon_repeater[3].header_icon
                    : options.header_icon_repeater[2].header_icon
                }
                alt="Otvori Modal"
                className="cursor-pointer"
                onClick={openModal}
              />
              {cartProductCount !== 0 ? (
                <span
                  id="cartItemCount"
                  className=" absolute -top-2 -right-5 bg-cruxGreen text-white rounded-full px-[7px] py-[2px] text-xs"
                >
                  {cartProductCount}
                </span>
              ) : null}
              <CartPopUpModal
                setCartProductCount={setCartProductCount}
                setReload={setReload}
                reload={reload}
                isOpen={isModalOpen}
                setIsCartModalOpen={setIsModalOpen}
                onClose={closeModal}
                data={data}
              />
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className={"flex flex-row mx-auto p-[30px]"}
            onMouseLeave={handleMouseLeave}
          >
            <div className={"flex flex-row mr-[50px] gap-[26px]"}>
              <img
                className={"cursor-pointer"}
                onClick={handleSeeCollagen}
                src={"/collagen.svg"}
              />
              <div
                className={
                  "flex flex-col justify-end text-center max-w-[220px] gap-[10px]"
                }
              >
                <div className="text-[#4C403A] text-[20pt] font-[300] tracking-[5px] leading-[30px]">
                  COLLAGEN <br /> PEPTIDES
                </div>
                <p className="text-[#8F7E76] text-[14px] font-[300]">
                  Oseti moć Crux kolagena, benefit sjajne kože, jake kose i
                  zdravih zglobova.
                </p>
                <button
                  onClick={handleSeeCollagen}
                  className={
                    "bg-[#F7F4ED] text-[#5B4841] mx-auto font-[700] tracking-[3px] text-[10pt]  rounded-[30px] h-[43px] w-[220px]"
                  }
                >
                  PROČITAJ VIŠE
                </button>
              </div>
            </div>
            <div className={"flex flex-row ml-[50px] gap-[26px]"}>
              <img
                className={"cursor-pointer"}
                onClick={handleSeeAshwagandha}
                width={200}
                height={100}
                src={"/cruxBottle.png"}
              />
              <div
                className={
                  "flex flex-col text-center justify-end max-w-[270px] gap-[10px]"
                }
              >
                <div className="text-[#4C403A] text-[20pt] font-[300] tracking-[5px]">
                  ASHWAGANDHA
                </div>
                <p className="text-[#8F7E76] text-[14px] font-[300]">
                  Oživite svoju vitalnost, čist i smiren um prirodnom snagom
                  ashwagandhe. Čista priroda i tajna iz Indije vekovima čuvana.
                </p>
                <button
                  onClick={handleSeeAshwagandha}
                  className={
                    "bg-[#F7F4ED] text-[#5B4841] font-[700] tracking-[3px] text-[10pt] mx-auto rounded-[30px] h-[43px] w-[220px] mt-[18px]"
                  }
                >
                  PROČITAJ VIŠE
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderNav;
